import React from "react"
import { Link } from "gatsby"

const Pagination = ({
  previousPagePath,
  totalPages,
  nextPagePath,
  pageNumber,
  uri,
}) => {
  const isFirst = pageNumber === 1
  const isLast = pageNumber === totalPages
  return (
    <div className="pagination">
      <ul>
        {!isFirst && (
          <Link to={previousPagePath} rel="prev">
            {"← Prev"}
          </Link>
        )}
        {Array.from({ length: totalPages }, (_, i) => (
          <li
              key={`pagination-number${i + 1}`}
              className={pageNumber === i + 1 && "active"}
          >
            <Link to={`${uri}${i === 0 ? "" : `${i + 1}/`}`}>{i + 1}</Link>
          </li>
        ))}
        {!isLast && (
            <Link to={nextPagePath} rel="next">
              {"Next →"}
            </Link>
        )}
      </ul>
    </div>
  )
}

export default Pagination
