import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Pagination from "./partials/pagination"
import SidebarPosts from "./partials/sidebar-posts"
import Seo from "../components/seo"

const Tag = ({ data, pageContext }) => {
  const tag = data.wpTag
  const { pageNumber, totalPages, nextPagePath, previousPagePath } = pageContext
  return (
    <Layout className="post-template container__tablet">
      <Seo title={`Tag: ${tag.name}`} pageUri={tag.uri} />
      <div className="post-main">
        <p className="custom-title-2">Tag: {tag.name}</p>
        {data.allWpPost?.nodes ? (
          <>
            <div className="posts-grid __2">
              {data.allWpPost.nodes.map(post => {
                return (
                  <Link title={post.title} to={post.uri}>
                    <div className="post">
                      <div className="post-image">
                        <Img
                          fluid={
                            post.featuredImage?.node?.localFile?.childImageSharp
                              ?.fluid
                          }
                          alt={post.title}
                        />
                      </div>
                      <div className="post-content">
                        <p className="post-title">{post.title}</p>
                        <p className="post-date">{post.date}</p>
                      </div>
                    </div>
                  </Link>
                )
              })}
            </div>
            {totalPages > 1 && (
              <Pagination
                pageNumber={pageNumber}
                totalPages={totalPages}
                nextPagePath={nextPagePath}
                previousPagePath={previousPagePath}
                uri={tag.uri}
              />
            )}
          </>
        ) : (
          <p>No posts found</p>
        )}
      </div>
      <SidebarPosts />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!, $offset: Int!, $postsPerPage: Int!) {
    wpTag(id: { eq: $id }) {
      id
      name
      uri
    }
    allWpPost(
      limit: $postsPerPage
      sort: { fields: date, order: DESC }
      skip: $offset
      filter: { tags: { nodes: { elemMatch: { id: { eq: $id } } } } }
    ) {
      nodes {
        title
        uri
        date(formatString: "DD/MM/YYYY")
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Tag
