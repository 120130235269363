import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const SidebarPosts = () => {
  const categories = useStaticQuery(graphql`
    query {
      allWpCategory {
        nodes {
          name
          uri
        }
      }
    }
  `)
  if (!categories?.allWpCategory?.nodes) return null
  return (
    <div className="post-sidebar">
      <div className="sidebar sticky">
        <div className="sidebar-title">Browse Categories</div>
        <div className="sidebar-content">
          <ul>
            {categories.allWpCategory.nodes.map((category, index) => {
              return (
                <li key={index}>
                  <Link alt={category.name} to={category.uri}>
                    {category.name}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SidebarPosts
